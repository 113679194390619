import { useRouter } from 'next/dist/client/router';
import Head from 'next/head';
import { FC } from 'react';

import Favicon from './Favicon';

export interface MetaProps {
  title: string;
  description?: string;
  ogImage?: string;
  noIndex?: boolean;
}

const useAsPath = () => {
  const router = useRouter();
  if (router.route === '/billboard/[CinemaId]') {
    return '/';
  }

  return router.asPath === 'index' ? '/' : router.asPath;
};

const Meta: FC<MetaProps> = ({
  title,
  description,
  ogImage = `${process.env.NEXT_PUBLIC_APP_URL}/api/og`,
  noIndex,
}) => {
  const asPath = useAsPath();
  const url = `${process.env.NEXT_PUBLIC_APP_URL}${asPath}`;
  return (
    <Head>
      <title>{title}</title>
      {!!description && <meta name="description" content={description} />}
      <Favicon />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="citicinemas.com" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      <meta name="apple-itunes-app" content="app-id=1038452486" />
      {noIndex ? <meta name="robots" content="noindex" /> : null}
    </Head>
  );
};

export default Meta;
